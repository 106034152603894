import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button, StyledButton } from '@monbanquet/crumble'
import { breakpoints, colors } from '../theme'
import { EventContext } from './context/EventContext'
import ButtonGroup from './ButtonGroup'
import {
  defaultCategories,
  defaultBanquetTypes,
} from './context/EventContextDefaults'

const EventBanquetTypeSelector = ({ onSelected, ...props }) => {
  const {
    state: { banquetType, category },
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const selectBanquetType = banquetType => {
    dispatchEventCtx({
      type: 'UPDATE_BANQUET_TYPE',
      payload: {
        banquetType: banquetType.value,
      },
      analytics: { label: banquetType },
    })
    onSelected(banquetType)
  }

  return (
    <StyledEventBanquetTypeSelector {...props}>
      <div className="banquet-type-select">
        <ButtonGroup className="predefined-banquet-types">
          {defaultBanquetTypes &&
            defaultBanquetTypes
              .filter(
                bT =>
                  !(
                    bT.value === 'terroir' &&
                    (category === defaultCategories[0].value ||
                      category === defaultCategories[2].value)
                  ), // petit-dejeuner or pause-gourmande
              )
              .map(bT => (
                <Button
                  key={`banquet-type-${bT.label}`}
                  data-name={`${bT.label}`}
                  className={`banquet-type ${
                    banquetType === bT.value ? 'selected' : ''
                  }`}
                  onClick={() => selectBanquetType(bT)}
                >
                  <span>{bT.label}</span>
                  <span>&rarr;</span>
                </Button>
              ))}
        </ButtonGroup>
      </div>
    </StyledEventBanquetTypeSelector>
  )
}

const StyledEventBanquetTypeSelector = styled.div`
  ${StyledButton} {
    color: ${colors.brand.black};
    text-transform: none;
    letter-spacing: unset;
    height: 66px;
    background-color: white;
    border: none;
    border-radius: 37px;
    font-size: 1.1rem;
  }

  .banquet-type-select {
    display: flex;
    flex-grow: 1;

    ${StyledButton} {
      height: 66px;
      display: flex;
      justify-content: space-between;
      padding: 22px 33px;
      font-size: 19px;
    }

    .predefined-banquet-types {
      flex-grow: 1;
    }
  }

  .btn-link {
    background: none;
    border: none;
    outline: none;
    color: ${colors.text.light3};
    text-transform: none;
    letter-spacing: unset;
    font-size: 1.1rem;
    margin: 20px 0;
    padding: 0 5px;
    min-width: 0;

    &.selected {
      color: ${colors.text.semiLight};
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      box-shadow: none;
      background: none;
      text-decoration: underline;
    }

    &:active {
      background: none;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .banquet-type-select {
      flex-direction: column;
      align-items: stretch;

      ${StyledButton} {
        padding: 18px 20px;
        font-size: 15px;
      }

      .predefined-banquet-types {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
`

export default EventBanquetTypeSelector
export { StyledEventBanquetTypeSelector }
