import React from 'react'
import styled from 'styled-components'
import { breakpoints, layout } from '../../theme'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import EventBanquetTypeSelector, {
  StyledEventBanquetTypeSelector,
} from '../../components/EventBanquetTypeSelector'
import { useRedirect } from '../../hooks/useRedirect'
import { Button, Pulse } from '@monbanquet/crumble'
import { navigate } from '../../components/Link'
import { useWithDelay } from '../../hooks/useWithDelay'
import DemandeLayout from '../../components/DemandeLayout'

const BanquetTypePage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const submit = () =>
    navigate(`/demande/regime`, {
      state: { next: true },
    })
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  return (
    <StyledBanquetTypePage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle title="Choisissez votre type de banquet" />
            <form
              onSubmit={e => {
                e.preventDefault()
                submit()
              }}
            >
              <EventBanquetTypeSelector onSelected={() => submitWithDelay()} />
              <div className="btn-bar">
                <Button id="previous" onClick={() => navigate(-1)}>
                  <div>&rarr;</div>
                  <span>&nbsp;Précédent</span>
                </Button>
                <Button id="next" type="submit">
                  {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledBanquetTypePage>
  )
}

const StyledBanquetTypePage = styled.div`
  .page-content {
    max-width: 750px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
      margin-bottom: 50px;
    }
  }

  ${StyledEventBanquetTypeSelector} {
    margin-top: 40px;
  }
`

export default BanquetTypePage
export { StyledBanquetTypePage }
